<template>
  <layout-modal modalClass="modal-avatars" @close-modal="closeAvatarsModal">
    <template v-slot:header>
      <div class="title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'avatars.changeAvatar') }}
      </div>
    </template>

    <modal-content-avatars v-bind="props" @selectAvatar="selectAvatar" />

    <template v-slot:footer>
      <div class="actions">
        <button-base type="gray" size="md" @click="closeAvatarsModal">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
        </button-base>

        <button-base
          type="primary"
          size="md"
          :is-disabled="!selectedAvatarId || isProcessing"
          :is-processing="isProcessing"
          @click="emit('saveAvatar', selectedAvatarId)"
        >
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.save') }}
        </button-base>
      </div>
    </template>
  </layout-modal>
</template>

<script setup lang="ts">
  import { ModalName } from '@skeleton/consts/modals';

  const props = defineProps<{
    currentAvatarPath?: string;
  }>();

  const globalStore = useGlobalStore();
  const { popupsData, defaultLocalePopupsData, layoutData, defaultLocaleLayoutData } = storeToRefs(globalStore);
  const { getContent } = useProjectMethods();
  const modalStore = useModalStore();
  const { closeModal } = modalStore;
  const { isProcessing } = storeToRefs(modalStore);

  const selectedAvatarId = ref('');

  const selectAvatar = (avatarId: string): void => {
    selectedAvatarId.value = avatarId;
  };

  const emit = defineEmits(['saveAvatar']);

  const closeAvatarsModal = () => {
    closeModal(ModalName.AVATARS);
  };
</script>

<style src="~/assets/styles/components/modal/avatars.scss" lang="scss" />
